export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'ruimte en privacy',
    headline: 'De Yurt',
    shortDescription: 'Heeft u altijd al willen overnachten in een authentieke Mongoolse yurt? Dat kan op De ' +
        'Vlasboerderij.',
    description:
        'Deze yurt is volledig geïsoleerd, staat op een ruim gazon achterin de tuin, grenzend aan ' +
        'weilanden en akkers. Rust en privacy zijn hier gegarandeerd. ' +
        'De yurt is van alle gemakken voorzien: een 2-persoonsbed, eethoek, kast, zitbank en een buffet voor koffie en ' +
        'thee. De zitbank kan worden opengeklapt en biedt dan 2 extra slaapplaatsen. Kinderen kunnen eventueel in een ' +
        'zelf meegebracht tentje op het gazon slapen. Aan de rand van het gazon ligt uw eigen sanitairgebouwtje met ' +
        'toilet, douche en wasgelegenheid. \nU kunt gebruik maken van de buitenkeuken en een gasbarbecue en een ' +
        'gasbrander. Vanwege de serene sfeer in de yurt is er geen tv en wifi. Uiteraard kunt u online gaan via uw ' +
        'mobiele netwerk.',
    prijzen: 'Laagseizoen: €110 p.n.\n' +
        'Hoogseizoen*: €120 p.n.\n' +
        '* Juli, augustus en september, Pasen, Hemelvaartsdag, Pinksteren, mei- en herfstvakantie.\n' +
        'Prijzen zijn exclusief ontbijt. Ontbijt is mogelijk voor €7,50 per persoon',
    buttonLabel: 'More info',
    imgStart: 'start',
    img: 'images/yurt-01.jpg',
    alt: 'Mongolian yurt',
    sectionName: 'yurt',
    bullets: {
        bullet1: '2 volwassenen (+ 2 kinderen <14 jr)',
        bullet2: '36 m²',
        bullet3: 'Rustig en privé in prachtige tuin gelegen',
        bullet4: 'Eigen badkamer, buitenkeuken en groot gazon',
        bullet5: 'Sfeervol, authentiek en luxe/comfort in èèn'
    },
    points: {
        point1: 'Inchecken vanaf 16:00',
        point2: 'Uitchecken tot 11:00',
        point3: 'Huisdieren niet toegestaan',
        point4: 'Gratis parkeren direct voor de boerderij',
        point5: 'De yurt wordt verhuurd van mei t/m okt.',
        point6: 'Minimum verblijf 2 nachten m.u.v. jul. & aug.'
    }
};

export const homeObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'karakteristiek',
    headline: 'De Strandkamer',
    shortDescription:
        'De rustige strandkamer in de voormalige vlasschuur heeft prachtig uitzicht op de aangrenzende grote tuin en' +
        ' eigen terras met gedeeltelijke overkapping.',
    description:
        'De kamer beschikt over een zitgedeelte met een tv. Er is een mini ' +
        'koelkast en een buffet waar u koffie en thee kunt zetten.\n'
    ,
    prijzen: 'Laagseizoen: €110 p.n. \nHoogseizoen: €120 p.n. (juli, augustus en september, officiële feestdagen,' +
        ' mei-, herfst- en kerstvakantie)',
    buttonLabel: 'More info',
    imgStart: '',
    img: 'images/beachroom-01.jpg',
    alt: 'Vault',
    sectionName: 'beachroom',
    bullets: {
        bullet1: '1 tot 2 personen',
        bullet2: '20 m²',
        bullet3: 'Ruime eigen badkamer en terras',
        bullet4: 'Incl. ontbijt',
        bullet5: 'Incl. Wifi',
    },
    points: {
        point1: 'Inchecken vanaf 16:00',
        point2: 'Uitchecken tot 11:00',
        point3: 'Huisdieren niet toegestaan',
        point4: 'Gratis parkeren direct voor de boerderij',
        point5: 'Minimum verblijf 2 nachten',
    }

};

export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Ontdek',
    headline: 'De omgeving',
    shortDescription: 'Zonnemaire ligt centraal op Schouwen-Duiveland. De Noordzeestranden, de Oosterschelde en het' +
        ' Grevelingenmeer bevinden zich op korte afstand. In het stadje Zierikzee kunt u vele monumenten ontdekken. ' +
        'Er zijn ook veel horecavoorzieningen te vinden.',
    description:
        'Vanwege de centrale ligging is Zonnemaire uitermate geschikt voor fietstochten (of een cabriotour) over' +
        ' het eiland. Ook voor strandgangers en duikers is het een goede locatie (Grevelingen, Oosterschelde). ' +
        'De dichtstbijziijnde supermarkt vindt op op 3 km in Brouwershaven, of op 7 km in Zierikzee.\n' +
        'Schouwen-Duiveland biedt toeristen een scala aan recreatieve mogelijkheden:\n',
    omgeving1:
        '✓ Wandelen langs het strand en/of in de bossen van Westerschouwen\n' +
        '✓ Duikerslocaties aan het Grevelingenmeer en de Oosterschelde\n' +
        '✓ Fietstochten over het platteland en dwars door de duinen\n' +
        '✓ Mountainbiken: het parcours in Westerschouwen\n' +
        '✓ Ruiterpaden in de boswachterij Westerschouwen\n' +
        '✓ Rondvaarten inclusief het spotten van zeehonden\n',
    omgeving2:
        'Wanneer het weer eens niet meewerkt kunt u musea bezoeken. Vooral het watersnoodmuseum mag u niet missen! ' +
        'Ook het Deltapark Neeltje Jans is een absolute aanbeveling voor jong en oud. Kinderen kunnen er spelen en ' +
        'ontdekken, terwijl volwassenen exposities kunnen bezoeken alsmede een pijler van de Oosterscheldekering.',
    buttonLabel: 'More info',
    imgStart: '',
    img: 'images/yurt-01.jpg',
    alt: 'Mongolian yurt',
    sectionName: 'omgeving',
};

export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Cruising',
    headline: 'Cabrio',
    shortDescription: 'Wat is er heerlijker dan om bij mooi weer in een klassieke cabrio door Zeeland te toeren? \n' +
        'De te verhuren cabrio is een in goede staat verkerende Fiat barchetta, een “youngtimer”, gebouwd in 1996. ' +
        'Kosten huur cabrio: €50 per dag',

    buttonLabel: 'Learn More',
    imgStart: 'start',
    img: 'images/barchetta-01.jpg',
    alt: 'Vault',
    sectionName: 'barchetta',
    prijzen: 'Kosten huur cabrio: €50 per dag'
};
