
import React from 'react';
import RoomSection from '../../RoomSection';
import {homeObjOne, homeObjThree, homeObjTwo} from './Data';
import CarouselComponent from "../../carousel/carousel";
import IntroComponent from "../Intro";

function Home() {
    return (
        <>
            <CarouselComponent/>
            <IntroComponent/>
            <RoomSection {...homeObjTwo} />
            <RoomSection {...homeObjOne} />
            <RoomSection {...homeObjThree} />
        </>
    );
}

export default Home;
