import React from 'react';
import './Footer.css';
import {Link} from 'react-router-dom';
import {FaPhone,} from 'react-icons/fa';
import {MdEmail} from "react-icons/all";
import * as Scroll from "react-scroll";

let scroll = Scroll.animateScroll;

function scrollToTop() {
    scroll.scrollToTop();
}

function Footer() {
    return (
        <section className='contact'>
            <div className='footer-container'>
                <div className='footer-links'>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>Contact</h2>
                            <p><FaPhone className='footer-icon'/>06-43085247</p>

                            <Link to='f.bastemeijer@icloud.com'>
                                <p><MdEmail className='footer-icon'/>f.bastemeijer@icloud.com</p>
                            </Link>
                        </div>
                    </div>

                </div>
                <section className='social-media'>
                    <div className='social-media-wrap'>
                        <div className='footer-logo'>
                            <a href="/" className='social-logo' onClick={scrollToTop}>
                                <img src='../../images/logo.png' width='20px' style={{marginRight: 10}} alt='logo'/>
                                <span className='footer-name'>B&B De Vlasboederij</span>
                                <span className='footer-name copyright'>© 2021</span>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default Footer;
