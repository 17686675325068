import React from 'react';
import Carousel from 'react-img-carousel';
require('react-img-carousel/lib/carousel.css');

class BarchettaCarouselComponent extends React.Component {

    onClickItemEvent = (...args) => {
        console.log(args);
    }

    render() {
        return (
            <div className="carousel-wrapper">
                <Carousel arrows={true} viewportHeight={'auto'} width={'auto'}
                          slideWidth={'auto'} slideHeight={'auto'} autoplay={false}
                          dots={false} lazyLoad={true} imagesToPrefetch={1}>
                    <img src="../../../images/barchetta-01.jpg" alt=""/>
                    <img src="../../../images/barchetta-02.jpg" alt=""/>
                    <img src="../../../images/barchetta-03.jpg" alt=""/>
                </Carousel>
            </div>
        );
    }
}

export default BarchettaCarouselComponent;