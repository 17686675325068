import React, {useState} from 'react';
import './RoomSection.css';
import {Button} from './Button';
import YurtCarouselComponent from "./carousel/yurtCarousel";
import BeachCarouselComponent from "./carousel/beachCarousel";
import ZonnemaireCarouselComponent from "./carousel/zonnemaireCarousel";
import {FaWifi} from "react-icons/fa";
import {AiFillCar, AiOutlineCheck, BsArrowsFullscreen, BsPeopleFill, ImEnter, ImExit, MdPets} from "react-icons/all";
import BarchettaCarouselComponent from "./carousel/barchettaCarousel";


// let Link = Scroll.Link;
// let scroll = Scroll.animateScroll;

// function scrollToTop() {
//     scroll.scrollToTop();
// }

function RoomSection({
                         lightBg,
                         topLine,
                         lightText,
                         lightTextDesc,
                         headline,
                         shortDescription,
                         description,
                         buttonLabel,
                         img,
                         alt,
                         imgStart,
                         sectionName,
                         bullets,
                         prijzen,
                         points,
                         omgeving1,
                         omgeving2
                     }) {
    const [readMore, setReadMore] = useState(false);

    const linkName = readMore ? 'Lees minder << ' : 'Lees Meer >> '

    const readMoreButton =
        <>
            <br/>
            <span className="read-more-link" onClick={() => {
                setReadMore(!readMore)
            }}>
                <Button buttonSize='btn--small' buttonColor='blue'>
                    {linkName}
                </Button>
            </span>
        </>

    function renderSwitch(el) {
        switch (el) {
            case 'yurt':
                return <YurtCarouselComponent/>
            case 'beachroom' :
                return <BeachCarouselComponent/>
            case 'omgeving':
                return <ZonnemaireCarouselComponent/>
            case 'barchetta':
                return <BarchettaCarouselComponent/>
            default:
                return <div></div>
        }
    }

    function renderLastBullet(prop) {
        if (prop.includes('Wifi')) {
            return <div className="features"><FaWifi/> {prop}</div>
        } else {
            return <div className="features"><AiOutlineCheck/> {prop}</div>
        }
    }


    function NewlineText(props) {
        const text = props.text;
        return text.split('\n').map(str => <p className='newline'>{str}</p>)
    }

    function NewLineSmallGap(props) {
        const text = props.text;
        return text.split('\n').map(str => <p className='newlineprices'>{str}</p>)
    }

    function OmgevingSmallGap(props) {
        return props.split('\n').map(str => <p className='newlineprices'>{str}</p>)
    }


    function renderBullets(props) {
        if (props) {
            return (<div className="flex-container">
                <div className="features"><BsPeopleFill/> {props.bullet1}</div>
                <div className="features"><BsArrowsFullscreen/> {props.bullet2}</div>
                <div className="features"><AiOutlineCheck/> {props.bullet3}</div>
                <div className="features"><AiOutlineCheck/> {props.bullet4}</div>
                {renderLastBullet(props.bullet5)}
            </div>)
        }
    }

    function showPointsBeach(props) {
        if (props) {
            return (<div className="flex-container">
                <div className="features"><ImEnter/> {props.point1}</div>
                <div className="features"><ImExit/> {props.point2}</div>
                <div className="features"><MdPets/> {props.point3}</div>
                <div className="features"><AiFillCar/> {props.point4}</div>
                <div className="features"><AiOutlineCheck/> {props.point5}</div>
            </div>)
        }
    }

    function showPointsYurt(props) {
        if (props) {
            return (<div className="flex-container">
                <div className="features"><ImEnter/> {props.point1}</div>
                <div className="features"><ImExit/> {props.point2}</div>
                <div className="features"><MdPets/> {props.point3}</div>
                <div className="features"><AiFillCar/> {props.point4}</div>
                <div className="features"><AiOutlineCheck/> {props.point5}</div>
                <div className="features"><AiOutlineCheck/> {props.point6}</div>
            </div>)
        }
    }

    function renderPrice(props) {
        switch (props.sectionName) {
            case 'beachroom':
                return (
                    <>
                        <div className='pointsTitle'>Prijzen (incl. ontbijt & toeristenbelasting)
                        </div>
                        <NewLineSmallGap text={prijzen}/>
                    </>
                )
            case 'yurt':
                return (
                    <>
                        <div className='pointsTitle'>Prijzen (incl. toeristenbelasting)</div>
                        <NewLineSmallGap text={prijzen}/>
                    </>
                )
            case 'barchetta':
                return (
                    <>
                        <div className='pointsTitle'>Prijzen</div>
                        <NewLineSmallGap text={prijzen}/>
                    </>
                )
            default:
                return <div></div>
        }
    }

    function renderPoints(props) {
        switch (props.sectionName) {
            case 'beachroom':
                return (
                    <>
                        <div className='pointsTitle'>Wat je moet weten</div>
                        {showPointsBeach(props.points)}
                    </>
                )
            case 'yurt':
                return (
                    <>
                        <div className='pointsTitle'>Wat je moet weten</div>
                        {showPointsYurt(props.points)}
                    </>
                )
            default:
                return <div></div>
        }
    }

    function renderomgeving1(props){
            if(props.sectionName === "omgeving") {
                return OmgevingSmallGap(props.omgeving1)
                // return <NewLineSmallGap text={omgeving1}></NewLineSmallGap> //same as this
            }
    }

    function renderomgeving2(props){
        if(props.sectionName === "omgeving") {
            return <NewlineText text={omgeving2}></NewlineText>
        }
    }

    const complexDescription =
        <>
            <div className="complexDescription">
                <NewlineText text={description}/>
                {renderomgeving1({sectionName,omgeving1})}
                {renderomgeving2({sectionName,omgeving2})}
                {renderPoints({sectionName, points})}
                {renderPrice({sectionName})}
            </div>
        </>

    const simpleDescription =
        <>
            <div className="simpleDescription"> {shortDescription}</div>
        </>


    return (
        <section className={sectionName}>
            <div
                className={lightBg ? 'home__hero-section lightBg' : 'home__hero-section darkBg'}
            >
                <div className='container'>
                    <div
                        className='row home__hero-row'
                        style={{
                            display: 'flex',
                            flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
                        }}
                    >
                        <div className='col'>
                            <div className='home__hero-text-wrapper'>
                                <div className='top-line'>{topLine}</div>
                                <h1 className={lightText ? 'heading' : 'heading dark'}>
                                    {headline}
                                </h1>
                                <div
                                    className={
                                        lightTextDesc
                                            ? 'home__hero-subtitle'
                                            : 'home__hero-subtitle dark'
                                    }
                                >

                                    {renderBullets(bullets)}

                                    {simpleDescription}

                                    {!readMore && readMoreButton}

                                    {readMore && complexDescription}

                                    {readMore && readMoreButton}
                                </div>
                            </div>
                        </div>
                        <div className='col colcarousel'>
                            <div className='home__hero-img-wrapper'>
                                {renderSwitch(sectionName)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RoomSection;
