import React, {useState} from 'react';
import * as Scroll from 'react-scroll';
import {FaBars, FaTimes} from 'react-icons/fa';
import {Button} from "../Button";
import './Navbar.css'
import {IconContext} from "react-icons/lib";

let Link = Scroll.Link;

function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    window.removeEventListener('resize', showButton)


    return (
        <IconContext.Provider value={{ color: '#fff'}}>
            <div className="user">
            <div className="navbar">
                <div className="navbar-container container">
                    <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                        <img src='../../images/logo.png' width='35px' style={{marginRight: 10}} alt='logo'/>
                        <span className="navbar-name">B&B De Vlasboerderij</span>
                    </Link>
                </div>
                <div className="menu-icon" onClick={handleClick}>
                    {click ? <FaTimes/> : <FaBars/>}
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to='beachroom' spy={true} smooth={true} duration={500} className="nav-links" onClick={closeMobileMenu}>
                            Strandkamer
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='yurt' spy={true} smooth={true} duration={500} className="nav-links" onClick={closeMobileMenu}>
                            Yurt
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='omgeving' spy={true} smooth={true} duration={500} className="nav-links" onClick={closeMobileMenu}>
                            Omgeving
                        </Link>
                    </li>
                    <li className="nav-btn">
                        {button ? (
                                <Link  to='contact' spy={true} smooth={true} duration={500}  className="btn-link" onClick={closeMobileMenu}>
                                    <Button buttonStyle='btn--outline' class='contact'> Contact</Button>
                                </Link>) :
                            (<Link className="btn-link">
                                <Link to='contact' spy={true} smooth={true} duration={500}  className="btn-link" onClick={closeMobileMenu}>
                                    <Button buttonStyle='btn--outline'  class='contact' buttonSize='btn-mobile'> Contact</Button>
                                </Link>
                            </Link>)
                            }
                    </li>
                </ul>
            </div>
        </div>
        </IconContext.Provider>
    )
}

export default Navbar
