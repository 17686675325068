import React from 'react';
import Carousel from 'react-img-carousel';
import './overrides.css';  // change this to the file path of your overrides
require('react-img-carousel/lib/carousel.css');
require('./overrides.css');

class ZonnemaireCarouselComponent extends React.Component {

    onClickItemEvent = (...args) => {
        console.log(args);
    }

    render() {
        return (
            <div className="carousel-wrapper whiteoverride">
                <Carousel arrows={true} viewportHeight={'auto'} width={'auto'}
                          slideWidth={'auto'} slideHeight={'auto'} autoplay={false}
                          dots={false} lazyLoad={true} imagesToPrefetch={3}>
                        <img src="../../../images/omgeving0.jpg" alt=""/>
                        <img src="../../../images/omgeving1.jpg" alt=""/>
                        <img src="../../../images/omgeving2-6105.jpg" alt=""/>
                        <img src="../../../images/omgeving3-184722.jpg" alt=""/>
                        <img src="../../../images/omgeving4-00186.jpg" alt=""/>
                        <img src="../../../images/omgeving5-7154.jpg" alt=""/>
                        <img src="../../../images/omgeving6-8880.jpg" alt=""/>
                        <img src="../../../images/omgeving7-9271.jpg" alt=""/>
                        <img src="../../../images/omgeving8-0981.jpg" alt=""/>
                        <img src="../../../images/omgeving9-5917.jpg" alt=""/>
                </Carousel>
            </div>
        );
    }
}

export default ZonnemaireCarouselComponent;
