import React from 'react';
import './Intro.css';
// import ScrollText from 'react-scroll-text'
import {ImQuotesLeft, ImQuotesRight} from "react-icons/all";


function IntroComponent() {
    return (
        <section className='intro'>
            <div className='intro-container'>
                {/*<ScrollText>*/}
                <ImQuotesLeft/>
                &nbsp; B&B De Vlasboerderij ligt centraal op het mooiste Zeeuwse eiland Schouwen-Duiveland.
                Een karakteristieke boerderij van 1850 voor de verwerking van de vlasteelt.
                Authentiek en sfeervol! De Mongoolse yurt en de Strandkamer zijn twee comfortabele verblijven.
                De fantastische tuin verzekert u van rust, ruimte en privacy. Een ervaring om nooit te vergeten. &nbsp;
                <ImQuotesRight/>
                <div className={'borderbottom'}></div>
                {/*</ScrollText>*/}
            </div>
        </section>
    );
}

export default IntroComponent;
