import React from 'react';
import Carousel from 'react-img-carousel';
require('react-img-carousel/lib/carousel.css');

export default function YurtCarouselComponent() {
    return <Carousel arrows={true} viewportHeight={'auto'} width={'auto'}
                     slideWidth={'auto'} slideHeight={'auto'} autoplay={false}
                     dots={false} lazyLoad={true} imagesToPrefetch={3}>

            <img src="../../../images/yurt1-8085.jpg" alt=""/>
            <img src="../../../images/yurt2-8600.jpg" alt=""/>
            <img src="../../../images/yurt3-8102.jpg" alt=""/>
            <img src="../../../images/yurt4-9205.jpg" alt=""/>
            <img src="../../../images/yurt5-8752.jpg" alt=""/>
            <img src="../../../images/yurt6-8434.jpg" alt=""/>
            <img src="../../../images/yurt7-9346.jpg" alt=""/>
            <img src="../../../images/yurt8-8623.jpg" alt=""/>
            <img src="../../../images/yurt9-8756.jpg" alt=""/>
            <img src="../../../images/yurt9b-8094.jpg" alt=""/>
            <img src="../../../images/yurt10-8431.jpg" alt=""/>
            <img src="../../../images/yurt11-8186.jpg" alt=""/>
            <img src="../../../images/yurt12-8621.jpg" alt=""/>
            <img src="../../../images/yurt12b-9486.jpg" alt=""/>
            <img src="../../../images/yurt12c-9484.jpg" alt=""/>
            <img src="../../../images/yurt13-8342.jpg" alt=""/>
            <img src="../../../images/yurt14-8112.jpg" alt=""/>
            <img src="../../../images/yurt15-9095.jpg" alt=""/>
            <img src="../../../images/yurt16-8637.jpg" alt=""/>
            <img src="../../../images/yurt17-8351.jpg" alt=""/>
            <img src="../../../images/yurt18-9090.jpg" alt=""/>
            <img src="../../../images/yurt19-9400.jpg" alt=""/>
    </Carousel>
}
