import React from 'react';
import Carousel from 'react-img-carousel';
import './overrides.css';
require('react-img-carousel/lib/carousel.css');
require('./overrides.css');

export default function BeachCarouselComponent() {
    return (
        <div className="carousel-wrapper whiteoverride" >
            <Carousel arrows={true} viewportHeight={'auto'} width={'auto'}
                      slideWidth={'auto'} slideHeight={'auto'} autoplay={false}
                      dots={false} lazyLoad={true} imagesToPrefetch={3}>
                    <img src="../../../images/strandkamer1-8161.jpg" alt=""/>
                    <img src="../../../images/strandkamer2-8575.jpg" alt=""/>
                    <img src="../../../images/strandkamer3-8162.jpg" alt=""/>
                    <img src="../../../images/strandkamer4-8283.jpg" alt=""/>
                    <img src="../../../images/strandkamer6-8827.jpg" alt=""/>
                    <img src="../../../images/strandkamer7-8592.jpg" alt=""/>
                    <img src="../../../images/strandkamer8-8375.jpg" alt=""/>
                    <img src="../../../images/strandkamer9-8805.jpg" alt=""/>
                    <img src="../../../images/strandkamer10-9211.jpg" alt=""/>
                    <img src="../../../images/strandkamer11-8715.jpg" alt=""/>
                    <img src="../../../images/strandkamer12-2.jpg" alt=""/>
                    <img src="../../../images/strandkamer13-8205.jpg" alt=""/>
                    <img src="../../../images/strandkamer14-8336.jpg" alt=""/>
                    <img src="../../../images/strandkamer15-9327.jpg" alt=""/>
            </Carousel>
        </div>
    );
}