import React from 'react';
import Carousel from 'react-img-carousel';
import './mainoverrides.css';
require('react-img-carousel/lib/carousel.css');
require('./mainoverrides.css');

export default function CarouselComponent() {
    return (
    <div className="carousel-wrapper whiteoverride" >
        <Carousel arrows={false} viewportHeight={'auto'} width={'auto'}
                     slideWidth={'auto'} slideHeight={'auto'} autoplay={true}
                     dots={false} lazyLoad={true} imagesToPrefetch={3}>
        <img src="../../../images/vlasboerderij1-8161.jpg" alt=""/>
        <img src="../../../images/vlasboerderij2-9205.jpg" alt=""/>
        <img src="../../../images/vlasboerderij3-8592.jpg" alt=""/>
        <img src="../../../images/vlasboerderij4-8085.jpg" alt=""/>
        <img src="../../../images/vlasboerderij5-8056.jpg" alt=""/>
        <img src="../../../images/vlasboerderij6-8283.jpg" alt=""/>
        <img src="../../../images/vlasboerderij7-9437.jpg" alt=""/>
        <img src="../../../images/vlasboerderij8-8434.jpg" alt=""/>
        <img src="../../../images/vlasboerderij9-8393.jpg" alt=""/>
        <img src="../../../images/vlasboerderij10-8600.jpg" alt=""/>
    </Carousel>
    </div>
    )
}